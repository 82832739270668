.home {
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center; 
    flex-direction: column;
    background: rgba(0, 0, 0, 0.7); 
    font-family: "Roboto", sans-serif !important;
}

.home:before {
    z-index: 777;
    content: '';
    position: absolute;
    background: rgba(0, 0, 0, 0.3); 
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.home .content {
    z-index: 888;
    color: #fff;
    width: 70%;
    margin-top: 50px;
    text-align: center; 
    display: none;
}

.home .content h1 {
    font-size: 48px; /* Adjust the font size as needed */
}

.home .content.active {
    display: block;
}

.home .content h2 {
    font-size: 5em; 
    font-weight: 700;
    text-transform: capitalize;;
    letter-spacing: 5px;
    line-height: 75px;
    margin-bottom: 20px; 
}

.home .content h2 span {
    font-size: 1em;
    font-weight: 600;
}

.home .content p {
    margin-bottom: 65px;
}

.home .content a {
    background: #fff;
    padding: 15px 35px;
    color: #09a6d4;
    font-size: 1.1em;
    font-weight: 500;
    text-decoration: none;
    border-radius: 2px;
}

.home .content a:hover {
    background: #09a6d4;
    color: #fff;
}

.heading{
    display: none;
}

.heading.display{
    display: inline;
}

.img_size.change{
    max-width: 100%;
    height: 400px;
}

.home img {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slider-navigation {
    z-index: 888;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-14px); 
    margin-top: 20px; 
    margin-bottom: 20px; 
}

.slider-navigation .nav-btn {
    width: 12px;
    height: 12px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(255, 255, 255, 0.5);
    transition: 0.3s ease;
}

.slider-navigation .nav-btn.active {
    background: #09a6d4;
}

.slider-navigation .nav-btn:not(:last-child) {
    margin-right: 20px;
}

.slider-navigation .nav-btn:hover {
    transform: scale(1.2);
}

.smallscreen {
    display: none;
}


.borderStyle {
    border-radius: 10px;
    padding: 20px;
    padding-top: 100px;
    margin: 20px;
  }
  
  .isActive {
    border: 10px solid #DE0F3F;
  }
  
/* Small devices (phones, up to 576px) */
@media screen and (max-width: 576px) {
    .home .content {
        width: 90%;
    }

    .home .content h1 {
        font-size: 36px; /* Adjust font size for smaller screens */
    }

    .home .content h2 {
        font-size: 5vw; /* Adjust font size for smaller screens */
        line-height: 20px; /* Adjust line height for smaller screens */
    }

    .smallscreen {
        display: flex;
    }

    
    .largescreen {
        display: none;
    }
}

/* Medium devices (tablets, 768px and up) */
@media screen and (min-width: 576px) and (max-width: 768px) {
    .home .content {
        width: 80%;
    }

    .home .content h1 {
        font-size: 42px; /* Adjust font size for medium screens */
    }

    .home .content h2 {
        font-size: 3em; /* Adjust font size for medium screens */
        line-height: 30px; /* Adjust line height for medium screens */
    }

    .smallscreen {
        display: flex;
    }
    
    .largescreen {
        display: none;
    }
}

/* Large devices (desktops, 992px and up) */
@media screen and (min-width: 768px) and (max-width: 992px) {
    .home .content {
        width: 60%;
    }

    .home .content h1 {
        font-size: 42px; /* Adjust font size for large screens */
    }

    .home .content h2 {
        font-size: 3em; /* Adjust font size for large screens */
        line-height: 40px; /* Adjust line height for large screens */
    }
}
@media screen and (min-width: 992px) and (max-width: 1050px) {
    .home .content {
        width: 70%;
    }

    .home .content h2 {
        font-size: 2.5em; /* Adjust font size for extra large screens */
        line-height: 65px; /* Adjust line height for extra large screens */
    }
}



/* Extra large devices (large desktops, 1200px and up) */

@media screen and (min-width: 1050px) and (max-width: 1500px) {
    .home .content {
        width: 70%;
    }

    .home .content h2 {
        font-size: 3em; /* Adjust font size for extra large screens */
        line-height: 65px; /* Adjust line height for extra large screens */
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media screen and (min-width: 1500px) {
    .home .content {
        width: 70%;
    }

    .home .content h2 {
        font-size: 4.5em; /* Adjust font size for extra large screens */
        line-height: 65px; /* Adjust line height for extra large screens */
    }
}






