
.container{
  font-family: 'Roboto', sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  background: rgba(0, 0, 0, 0.888);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.elements{
    margin: 20px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}



.social_text{
  display: flex;
  margin: 50px 30px;
  justify-content: center;
  align-items: center;
  
}

.social_text a{
  text-decoration: none;
  margin: 10px;
  &:hover{   
    transform: scale(1.05) translateY(-5px);
    cursor: pointer;
  }
}

.social_text h2{
  color: #fff;
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: 2px;
  border-radius: 20px;
  padding: 10px;
  margin: 10px;
  background-color: #5fc361;
  transition: all 0.5s ease-in-out;
  text-decoration: none;
}

.gform{
    flex: 0.5;
}


.social-tree{
    align-self: flex-start;
    margin: 30px;
    padding: 30px;
    max-height: fit-content;
}


.container iframe{
  flex: 0.5;
}

.contact_body {
    margin:40px;
    padding: 20px 10px;
}

.container button {
  overflow: visible;
}

.container button, select {
  text-transform: none;
}

.container button, input, select, textarea {
  color: #5A5A5A;
  font: inherit;
  margin: 0;
}

.container input {
  line-height: normal;
}

textarea {
  overflow: auto;
}



.container h1 {
  color: #c8c2bf;
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 7px;
  text-align: center;
  text-transform: uppercase;
}

.icon_wrapper {
  margin: 50px auto 0;
  width: 100%;
}

.icon {
  display: block;
  fill: #474544;
  height: 50px;
  margin: 0 auto;
  width: 50px;
}



.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 30px;
  padding: 30px;

}

.glass-btn {
  width: 100px;
  height: 100px;
  background-color: #fafafac8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  margin-left: 1em;
  margin-right: 1em;  
  cursor: pointer;
  transition: all 0.3s;

  &.blue-btn {
    -webkit-box-shadow: 0px 20px 20px -17px rgba(0, 111, 255, 0.53);
    -moz-box-shadow: 0px 20px 20px -17px rgba(0, 111, 255, 0.53);
    box-shadow: 0px 20px 20px -17px rgba(0, 111, 255, 0.53);

    &:hover {
      -webkit-box-shadow: 0px 20px 35px -16px rgba(0, 111, 255, 0.65);
      -moz-box-shadow: 0px 20px 35px -16px rgba(0, 111, 255, 0.65);
      box-shadow: 0px 20px 35px -16px rgba(0, 111, 255, 0.65);      
    }

    .content {
      font-size: 4em;
      font-family: "Roboto", sans-serif;
      background: linear-gradient(to right, #006fff, #00acff 70%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: 0px 10px 37px rgba(0, 111, 255, 0.8);
    }
  }

  &.green-btn {
    -webkit-box-shadow: 0px 20px 20px -17px rgba(95, 195, 97, 0.694);
    -moz-box-shadow: 0px 20px 20px -17px rgba(95, 195, 97, 0.694);
    box-shadow: 0px 20px 20px -17px rgba(95, 195, 97, 0.694);

    &:hover {
      -webkit-box-shadow: 0px 20px 35px -16px rgba(95, 195, 97, 0.694);
      -moz-box-shadow: 0px 20px 35px -16px rgba(95, 195, 97, 0.694);
      box-shadow: 0px 20px 35px -16px rgba(95, 195, 97, 0.694);      
    }

    .content {
      font-size: 4em;
      font-family: "Roboto", sans-serif;
      background: linear-gradient(to right, #006fff, #00acff 70%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: 0px 10px 37px rgba(0, 111, 255, 0.8);
    }
  }

  &.red-btn {
    -webkit-box-shadow: 0px 20px 20px -17px rgba(255, 16, 39, 0.5);
    -moz-box-shadow: 0px 20px 20px -17px rgba(255, 16, 39, 0.5);
    box-shadow: 0px 20px 20px -17px rgba(255, 16, 39, 0.5);

    &:hover {
      -webkit-box-shadow: 0px 20px 35px -16px rgba(255, 16, 39, 0.57);
      -moz-box-shadow: 0px 20px 35px -16px rgba(255, 16, 39, 0.57);
      box-shadow: 0px 20px 35px -16px rgba(255, 16, 39, 0.57);      
    }

    .content {
      font-size: 4em;
      font-family: "Roboto", sans-serif;
      background: linear-gradient(to right, #ff1027, #ff4f06 70%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: 0px 10px 37px rgba(255, 16, 39, 0.6);
    }
  }

  &.amber-btn {
    -webkit-box-shadow: 0px 20px 20px -17px rgba(16, 191, 255, 0.53);
    -moz-box-shadow: 0px 20px 20px -17px rgba(16, 191, 255, 0.53);
    box-shadow: 0px 20px 20px -17px rgba(16, 191, 255, 0.53);

    &:hover {
      -webkit-box-shadow: 0px 20px 35px -16px rgba(16, 191, 255, 0.53);
      -moz-box-shadow: 0px 20px 35px -16px rgba(16, 191, 255, 0.53);
      box-shadow: 0px 20px 35px -16px rgba(16, 191, 255, 0.53);      
    }

    .content {
      font-size: 4em;
      font-family: "Roboto", sans-serif;
      background: linear-gradient(to right, #ff8d00, #f7ad07 70%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: 0px 10px 37px rgba(255, 94, 16, 0.7);
    }
  }

  &:hover {
    background-color: darken(#fafafa, 2%);    
    transform: translateY(-5px);
    transition: all 0.3s;
  }
}

@media screen and (max-width: 1400px) {
  
  
  .loc_parent{
    display: flex;
    width: 50vw;
    align-items: center;
    justify-content: center;
    height: max-content;
  }

  .location{
    width: 30vw;
    height: 30vw;
  }
  
  .gform{
    width: 40vw;
  }

  .gform_parent{
    height: max-content;
    width: 50vw;
  }
}

@media screen and (max-width: 1200px) {
  .contact_body{
    margin-bottom: 10px;
  }
  .elements{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px;
  }
  #container {
    margin: 20px auto;
    width: 95%;
  }

  .loc_parent{
    display: flex;
    width: 100vw;
    align-items: center;
    justify-content: center;
    height: max-content;
  }

  .location{
    width: 60vw;
    height: 60vw;
  }
  .gform{
    width: 100vw;
  }

  .gform_parent{
    height: max-content;
    width: 100vw;
  }
  
}

@media screen and (max-width: 600px) {
  .container{
    align-items: center;
    justify-content: center;
  }
  .contact_body h1 {
    font-size: 6.2vw;
  }

  .contact_body{
    margin-bottom: 5px;
  }
  .social-tree{
    margin-top: 0px;
    padding-top: 0;
    padding-bottom: 0;
    align-items: center;
    justify-content: center;
  }

  .social_text{
    margin: 10px 5px 5px 5px;
  }

  .social_text img{
    max-width: 100%;
    height: auto;
  }
  
  .social_text h1{
    font-size: 6.2vw;
  }
  
  .social_text h2{
    font-size: 3vw;
    margin-left: 5px;
  }

  
  .underline {
    width: 68px;
  }
  
  #form_button {
    padding: 15px 25px;
  }

  
  .loc_parent{
    margin-bottom: 30px;
    width: 100vw;
  }

  .location{
    width: 90vw;
    height: 90vw;
  }

  .button-container{
    display: grid;
    grid-template-columns: repeat(auto-fit,30vw);
    gap: 20px 0;
    justify-content: center;
    align-items: center;
  }

  .gform{
    width: 100vw;
  }
}


