@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

.navbar {
    position: sticky;
    top: 0%;
    z-index: 998;
    height: max-content;
    background-color: #ececec;
    border-bottom: 2px solid rgb(195, 195, 195);
    display: flex;
    width: 100vw;
    align-items: center;
    justify-content: space-between;
    padding: 20px 80px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
    font-family: "Roboto", sans-serif !important;
    overflow-x: clip;
  }

  .navbar img{
    max-width: 100%;
    height: auto;
  }
  
  .navbar-menu {
    list-style-type: none;
    display: flex;
    flex:0.6;
    padding: 0 20px;
    position: relative;
    justify-content: space-around;
    align-items: center;
    width: 650px;
  }
 
  .navbar-item {
    color: #333333;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 700;
    cursor: pointer;
    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
    margin-right: 15px;
  }
  
  .navbar-item:hover {
    transform: scale(1.15);
    color: #A23A38;
  }

  .navbar-item:after {
    content: '';
    display: block;
    margin: auto;
    height: 3px;
    width: 0px;
    background: transparent;
    transition: width .5s ease, background-color .5s ease;
  }
  .navbar-item:hover:after {
    width: 100%;
    background: #5E647F;
  }

  .navbar-logo{
    padding: 50px;
  }
  
  .mobile-nav{
    display: none;
  }

  @media screen and (max-width: 905px){

      .navbar{
        padding: 20px 30px;
      }
      .mobile-nav{
        display: block;
      }
      .navbar-menu{
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        top: 129.3px;
        right: -3000px;
        width: 200px;
        height: 100vh;
        background: #ececec;
        box-shadow: -10px 0px 10px 0px rgba(0, 0, 0, 0.15);
        padding: 50px 0 0 20px;
        transition: 0.3s ease-in-out;
      }
      .navbar-menu.active{
        right:0px;
      }
      .navbar-item{
        padding: 10px;
      }
  }