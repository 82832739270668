.route_page{
    margin: 50px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100vw;
    font-family: 'Righteous', sans-serif;
    color: rgba(0, 0, 0, 1);
    font-size: 1.5rem;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    border-radius: 25px;
}

.top-part{
    display: flex;
    width: 100vw;
    justify-content: center;
}

.scroll-more{
    position: absolute;
    top:  20%;
    right: 5%;
    font-size: 0.9rem;
    height: max-content;
    padding: 10px;
    background-color: #A23A38;
    color: #ffffff;
    border-radius: 30%;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    &:hover{
        transform: scale(1.05);
        border-style: solid;
        border-color: #A23A38;
        border-width: 2px;
        box-shadow: 5px 5px 10px #a23a3880;
    }
}

.stamp{
    height: max-content;
    position: relative;
    top: 2%;
    right: 20%;
    margin: 20px 20px 0 0;
    padding: 40px;
    font-size: 1.2rem;
    background-color: #5bc3e5;
    color: #ffffff;
    border-radius: 100%;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    transition: all 0.3 ease-in-out;
    &:hover{
        transform: scale(1.05);
        border-style: solid;
        border-color: #A23A38;
        border-width: 2px;
        box-shadow: 5px 5px 10px #a23a3880;
    }

}

.stamp1{
    height: max-content;
    position: relative;
    top: 2%;
    right: 10%;
    margin: 20px 20px 0 0;
    padding: 40px;
    font-size: 1.2rem;
    background-color: #5bc3e5;
    color: #ffffff;
    border-radius: 100%;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    transition: all 0.3 ease-in-out;
    &:hover{
        transform: scale(1.05);
        border-style: solid;
        border-color: #A23A38;
        border-width: 2px;
        box-shadow: 5px 5px 10px #a23a3880;
    }

}

@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 10px); }
    100%   { transform: translate(0, -0px); }    
}

.Title{
    position: relative;
    right: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    padding: 20px;
}

.Title h2{
    color: #A23A38;
}

.Title-dosa{
    position: relative;
    right: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    padding: 20px;
}

.Title-dosa h2{
    color: #A23A38;
}

.Title_fruit{
    /* grid-row: 1;
    grid-column: 1/4; */
    align-self: center;
    justify-self: center;
    margin: 20px;
    padding: 5px 20px 20px 20px;
    font-size: 2rem;
}

.Title_fruit h1{
    font-size: 5rem;
    text-align: center;
}
.Title_dosa h2{
    color: #A23A38;
}

.Steps{
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content: center;
}
.Steps_list{
    text-align: left;
    flex: 33%;
    padding: 20px;
    font-family: "Roboto", sans-serif; 
    font-size: 1.6rem;
    font-weight: 1000;
    color: #363636;
}

.Steps ul{
    list-style-position: inside;
}

.Steps-item {
  position: relative;
  padding: 20px;
  color: #181818;
}

.image{
    display: flex;
    margin: 10px;
    padding: 10px;
    max-width: max-content;
    align-items: center;
    justify-content: center;
}

.image h2{
    color: #A23A38;
    margin: 10px;
}

.image img{ 
    width: 400px;
    height: auto;
    border-radius: 20px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    transition: all 0.3 ease-in-out;
    &:hover{
        transform: scale(1.05);
        border-style: solid;
        border-color: #A23A38;
        border-width: 2px;
        border-radius: 20px;
        box-shadow: 5px 5px 10px #a23a3880;
    }
}

.image-container {
    display: flex;
    justify-content: space-between; 
    margin-top: 20px; 
}


.table_image{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    padding: 10px;
}

.table_image h2{
    color: #A23A38;
    margin: 10px;
}

.table_image img{ 
    width: 400px;
    margin: 10px;
    height: auto;
    border-radius: 20px;
    box-shadow: 5px 5px 10px #a23a3880;
    border-color: #a23a38ca;
    border-width: 2px;
    border-style: solid;
    transition: all 0.3 ease-in-out;
    &:hover{
        transform: scale(1.05);
        border-style: solid;
        border-color: #A23A38;
        border-width: 2px;
        border-radius: 20px;
        box-shadow: 5px 5px 10px #a23a3880;
    }
}

.side-by-side-image {
    margin: 10px;
    padding: 10px;
}

@media screen and (max-width:1500px) {
    .stamp{
        right: 15%;
    }
    .stamp1{
        right: 0%;
    }
    .Title-dosa{
        right: 7%;
    }
    .image img{
        width: 350px;
    }
    .table_image img{
        width: 350px;
    }
    
    .Steps-item{
        font-size: 1.2rem;
        text-align: left;
    }
}


@media screen and (max-width:1400px) {
    .scroll-more{
        transform: scale(0.7);
    }

    .stamp{
        left: 0%;
    }
    
    .top-part{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .Steps{
        flex-direction: column;
    }

    .image img{
        max-width: 100%;
        height: auto;
    }

    .table_image{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .table_image img{
        margin: 20px;
    }

    .Title{
        font-size: 2.5vw;
        right: 0%;
    }
    
    .Title-dosa{
        font-size: 2.5vw;
        right: 0%;
    }
    .Steps-item{
        font-size: 2vw;
    }
}



@media screen and (max-width: 800px) {
    
    .stamp{
        width: 40vw;
        text-align: center;
        margin: 0px;
        padding: 10px;
        font-size: 3vw;
    }
    .stamp1{
        width: 40vw;
        text-align: center;
        margin: 0px;
        padding: 10px;
        font-size: 3vw;
    }

    .scroll-more{
        width: 20vw;
        text-align: center;
        top: 15%;
        right: 2%;
        margin: 20px;
        padding: 10px;
        font-size: 1.2vw;
    }

    .route_page{
        width: 95vw;
        margin: 10px;
    }

    .Title{
        text-align: center;
        font-size: 4vw;
        margin: 20px 10px 0px 10px;
        padding: 10px 10px 0px 10px;
    }
    
    .Title-dosa{
        text-align: center;
        font-size: 4vw;
        margin: 50px 10px 0px 10px;
        padding: 10px 10px 0px 10px;
    }
    
    

    .Steps{
        justify-content: center;
        padding: 10px 20px;
        margin-top: 10px;
    }

    .Steps ul{
        list-style-position: inside;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: max-content;
    }
    
    .table_image{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .table_image img{
        width: 80vw;
    }

    .image{
        align-items: center;
        justify-content: center;
    }

    .Steps-item{
        font-size: 3.5vw;
        text-align: left;
        padding: 10px;
        width: 90vw;
    }

    .image img{
        width: 60vw;
    }

    .side-by-side-image{
        padding: 10px;
    }
}