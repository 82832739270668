.products {
  display: grid;
  gap: 40px 50px;
  height: 100%;
  width: 100vw;
  margin: 0 0 14px 0;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgb(195, 195, 195);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease; /* Adding animation to the products container */
}

.product-header {
  margin: 30px 0 20px 0;
  padding: 10px;
  grid-row: 1;
  grid-column: 1/4;
  justify-self: center;
  font-size: 3rem;
  font-weight: 1000;
  font-family: "Roboto", sans-serif !important;
  color: #181818;
}

.card {
  width: 450px;
  height: 450px; 
  margin-bottom: 20px;
  border-radius: 12px; 
  border: 1px solid rgba(255, 255, 255, 0.125);
  padding: 20px;
  filter: drop-shadow(0 30px 10px rgba(0,0,0,0.125));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: rgba(148, 148, 148, 0.25);
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(20px) saturate(180%);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
  transition: transform 5s ease-in-out;
  cursor: pointer;
  margin-right: 30px;
}

.card:hover {
  max-width: 100%;
  height:auto; 
  transform: scale(1.05);
  box-shadow: 0px 0px 30px 1px rgba(255, 77, 0, 0.208);
  border-color: #a23a38ba;
  border-style: solid;
  border-width: 3px;
  border-radius: 20px;
}

.wrapper {
  width: 100%;
  height: 100%;
}

.banner-image {
  overflow: hidden;
  height: 100%; 
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.255);
}

.banner-image img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover; 
  border-radius: 12px;
}

.card .wrapper h1 {
  font-family: 'Righteous', sans-serif;
  color: rgba(0, 0, 0, 1);
  font-size: 1.5rem;
  padding: 20px 10px;
  opacity: 0; 
  transition: opacity 0.2s ease; 
}

.card:hover .wrapper h1 {
  opacity: 1; 
}


.button-wrapper{
  margin-top: 18px;
  width: 300px;
}

.btn {
  border: none;
  padding: 12px 24px;
  border-radius: 24px;
  font-size: 12px;
  font-size: 0.8rem;  
  letter-spacing: 2px;  
  cursor: pointer;
}

.btn + .btn {
  margin-left: 10px;
}

.outline {
  background: transparent;
  color: #A23A38;
  border: 1px solid #A23A38;
  transition: all .3s ease-in-out;
  
}

.outline:hover{
  transform: scale(1.125);
  color: rgba(255, 255, 255, 0.9);
  border-color: #A23A38;
  transition: all .3s ease-in-out;  
}

.fill {
  background: #A23A38;
  color: rgba(255,255,255,0.95);
  filter: drop-shadow(0);
  font-weight: bold;
  transition: all .3s ease-in-out; 
}

.fill:hover{
  transform: scale(1.125);  
  border-color: rgba(255, 255, 255, 0.9);
  filter: drop-shadow(0 10px 5px rgba(0,0,0,0.125));
  transition: all .3s ease-in-out;    
}



@media screen and (max-width: 1550px) {
  .card{
    width: 25vw;
    height: 25vw;
  }
  .wrapper{
    max-height: 17vw;
  }
}


/* Media query for max-width: 1200px */
@media screen and (max-width: 1200px) {
  .products {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px 40px;
    margin: 20px;
  }
  .card{
    width: 300px;
    height: 300px;
  }
  
}

/* Media query for max-width: 900px */
@media screen and (max-width: 1050px) {
  .products {
    display: flex;
    flex-direction: column;
  }
  .product-header {
    font-size: 2.5rem;
  }

  .card {
    width: 300px;
    height: 300px;
  }
  .wrapper{
    max-height: 35vw;
  }
  .button-wrapper {
    width: 250px;
  }
}

@media screen and (max-width:650px) {
  .product-header{
    font-size: 7vw;
  }
  .wrapper{
    max-height: 35vw;
  }
  .wrapper:hover {
    max-height: 50vw;
  }
}

@media screen and (max-width:460px) {
  .wrapper{
    max-height: 50vw;
  }
  .card .wrapper h1{
    font-size: 1.2rem;
    padding: 9px;
  }
}







