.home2 {
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center; 
    flex-direction: column;
    background: rgba(0, 0, 0, 0.7); 
    font-family: "Roboto", sans-serif !important;
}

.home2:before {
    z-index: 777;
    content: '';
    position: absolute;
    background: rgba(0, 0, 0, 0.3); 
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.home2 .content {
    z-index: 888;
    color: #fff;
    width: 70%;
    margin-top: 50px;
    text-align: center; 
    display: none;
}

.home2 .content.active {
    display: block;
}

.home2 .content h1 {
    font-size: 5em; 
    font-weight: 900;
    text-transform: capitalize;;
    letter-spacing: 5px;
    line-height: 75px;
    margin-bottom: 20px; 
}

.home2 .content h1 span {
    font-size: 1.2em;
    font-weight: 600;
}

.home2 .content p {
    margin-bottom: 65px;
}

.home2 .content a {
    background: #fff;
    padding: 15px 35px;
    color: #09a6d4;
    font-size: 1.1em;
    font-weight: 500;
    text-decoration: none;
    border-radius: 2px;
}

.home2 .content a:hover {
    background: #09a6d4;
    color: #fff;
}

.home2 img {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slider-navigation {
    z-index: 888;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(32px); 
    margin-top: 20px; 
    margin-bottom: 20px; 
}

.slider-navigation .nav-btn {
    width: 12px;
    height: 12px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(255, 255, 255, 0.5);
    transition: 0.3s ease;
}

.slider-navigation .nav-btn.active {
    background: #09a6d4;
}

.slider-navigation .nav-btn:not(:last-child) {
    margin-right: 20px;
}

.slider-navigation .nav-btn:hover {
    transform: scale(1.2);
}

.small2 {
    display: none;
}

@media (min-width: 576px) {
    .home2 .content {
        width: 80%;
    }

    .home2 .content h1 {
        font-size: 3.5em;
        line-height: 60px;
    }
}

@media screen and (max-width: 2000px) {
    .home2 .content {
        width: 90%;
    }

    .home2 .content h1 {
        font-size: 4em;
        line-height: 65px;
    }
}

@media screen and (max-width: 900px) {
    .home2{
        padding: 30px;
    }
    .home2 .content {
        width: 70%;
    }

    .home2 .content h1 {
        font-size: 3.5em;
        line-height: 60px;
    }

    .small2 {
        display: flex;
    }
    
    .large {
        display: none;
    }
}

@media screen and (max-width: 650px) {
    .home2{
        margin-bottom: 10px;
        padding: 10px;
        min-height: 30vh;
    }

    .home2 .content {
        margin: 10px 10px 0px 10px;
        width: 90%;
        height: 500px;
    }

    .home2 .content h1 {
        padding: 5px;
        font-size: 7vw;
        line-height: 40px;
    }

    .small2{
        display: flex;
    }
    
    .small2 img{
        height: 620px;
    }

    .large {
        display: none;
    }
    .slider-navigation{
        margin-top: 0px;
    }
}


