*{
    margin: 0;
    box-sizing: border-box;
    overflow-x: clip;
    font-family: 'Roboto', sans-serif;
}

.App{
    background-color: #f4f4f4;
    min-width: 100vw;
}

::-webkit-scrollbar {
    width: 20px;
}
 
::-webkit-scrollbar-track {
    background-color: #e1e1e1;
    width: 30px;
    -webkit-border-radius: 20px;
    border-radius: 30px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #6d6d6d; 
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(152, 152, 152)
  }



  .whatsapp{
    display: flex;
    position: fixed;
    bottom: 10px;
    right: 40px;
    align-items: center;
    justify-content: center;
    z-index: 999;
    &:hover{
       transform: scale(1.05) translateY(-5px);
      cursor: pointer;
    }
  }
  
  .whatsapp a{
    text-decoration: none;
  }
  
  .whatsapp h2{
    color: #fff;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: 2px;
    border-radius: 20px;
    padding: 10px;
    margin: 10px;
    background-color: #5fc361;
    transition: all 0.5s ease-in-out;
    border-color: #A23A38;
    border-width: 3px;
    border-style: solid;
    text-decoration: none;
    -webkit-box-shadow: 0px 20px 20px -17px rgba(95, 195, 97, 0.694);
    -moz-box-shadow: 0px 20px 20px -17px rgba(95, 195, 97, 0.694);
    box-shadow: 0px 20px 20px -17px rgba(95, 195, 97, 0.694);
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    transition: all 0.3 ease-in-out;
  }

  @keyframes floating {
      0% { transform: translate(0,  0px); }
      50%  { transform: translate(0, 10px); }
      100%   { transform: translate(0, -0px); }    
  }


  
  .whatsapp a h2 img{
    margin-right: 10px;
    width: 1.5em;
  }

  @media screen and (max-width: 550px) {
    .text_chat{
      display: none;
    }
    .whatsapp a h2 img{
      width: 7vw;
      position: relative;
      top: 10%;
      left: 10%;
    }
  }

  

  .phone{
    display: flex;
    position: fixed;
    bottom: 80px;
    right: 40px;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
    z-index: 999;
    &:hover{
       transform: scale(1.05) translateY(-5px);
      cursor: pointer;
    }
  }
  
  .phone a{
    text-decoration: none;
  }
  
  .phone h2{
    color: #fff;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: 2px;
    border-radius: 20px;
    padding: 10px;
    margin: 10px;
    background-color: #5fc3a2;
    border-color: #A23A38;
    border-width: 3px;
    border-style: solid;
    transition: all 0.5s ease-in-out;
    text-decoration: none;
    -webkit-box-shadow: 0px 20px 20px -17px rgba(95, 195, 97, 0.694);
    -moz-box-shadow: 0px 20px 20px -17px rgba(95, 195, 97, 0.694);
    box-shadow: 0px 20px 20px -17px rgba(95, 195, 97, 0.694);
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    transition: all 0.3 ease-in-out;
  }

  @keyframes floating {
      0% { transform: translate(0,  0px); }
      50%  { transform: translate(0, 10px); }
      100%   { transform: translate(0, -0px); }    
  }


  
  .phone a h2 img{
    margin-right: 10px;
    width: 1.5em;
  }

  @media screen and (max-width: 550px) {
    .text_chat{
      display: none;
    }
    .phone a h2 img{
      width: 7vw;
      position: relative;
      top: 10%;
      left: 10%;
    }
  }
    