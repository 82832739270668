.homepage3{
    display: flex;
    max-width: 100vw;
    height: max-content;
    border-bottom: 2px solid rgb(195, 195, 195);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  }
  
  .about-us{
    flex: 0.9;
    margin: 40px;
    padding: 20px 20px 0 20px;
    font-size: 2rem;
    font-weight: 300;
    font-family: "Roboto", sans-serif !important;
  }
  
  .about-us h2{
    padding: 40px 20px 40px 0;
    color: #474646;
    font-weight: 600;
  }
  
  .about-us p{
    line-height: 150%;
    padding: 20px 0 50px 0;
  }
  
  .imgs3{
    flex: 0.1;
    background: #A23A38;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    height: auto;
    box-shadow: -10px 0px 10px 0px rgba(0, 0, 0, 0.15);
  }
  .imgs3 img{
    margin: 50px;
    box-shadow: -10px 0px 10px 0px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    padding: 10px;
    max-width: 100%;
    height: auto;
  }

  
@media screen and (max-width: 1500px) {
  .homepage3{
    height: max-content;
  }
  .about-us{
    font-size: 1.5rem;
  }
  .imgs3 img{
    width: 80%;
  }
}


  @media screen and (max-width: 1050px) {
    .main2{
      background-image: url('../assests/aboutusbg.jpg');
      background-size: contain;
    }

    .homepage3{
      flex-direction: column;
      height: max-content;
      border: #A23A38 solid 2px;
      border-radius: 20px;
      background: rgba(122, 122, 122, 0.605);
      backdrop-filter: saturate(180%) blur(3px);
      -webkit-box-shadow: 0px 20px 20px -17px rgba(219, 75, 75, 0.645);
      -moz-box-shadow: 0px 20px 20px -17px rgba(219, 75, 75,0.645);
      box-shadow: 0px 20px 20px -17px rgba(219, 75, 75, 0.645);
    }
   .imgs3{
      display: none;
    }
    .about-us{
      font-size: 1rem;
    }

    .about-us h2{
      text-align: left;
      color: #e8ccf8;
    }

    .about-us p{
      text-align: left;
      color: #fff;
      font-weight: 500;
      padding: 0px;
    }


} 