.main1{
  background-image: linear-gradient(to bottom,#a8a8a8,#fff);
  background-size: cover;
}

.homepage1 {
  display: flex;
  max-width: 100vw;
}

.homepage-title{
  display: none;
}

.homepage1 img {
  max-width: 100%;
  height: auto;
  padding: 30px;
}

.text-1 {
  flex: 0.5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 30px;
}

.typewriter {
  font-family: Lucida Sans serif Typewriter, Lucida Console, monaco, Bitstream Vera Sans serif Mono, monospace;
  font-size: 2rem;
  font-weight: 1000;
  padding: 20px;
}

.contentlist {
  padding: 20px;
  font-family: 'Inter', sans-serif;
  font-size: 1.8rem;
  font-weight: 1000;
  color: #363636;
}

.contentlistitem {
  padding: 20px;
  font-family: Lucida Console, monaco, Bitstream Vera Sans serif Mono, monospace;
  font-size: 1.8rem;
  font-weight: 4000;
  color: #181818;
  font-family: 'Inter', sans-serif;
}

.contentlistitem::before {
  content: '\2022';
  color: #181818;
  font-size: 2.8rem;
  margin-right: 10px;
  vertical-align: middle;
  display: inline-block;
}

#twh1 {
  color: #363636;
}

#twsp {
  color: #A23A38;
}

.dosa {
  align-items: center;
  justify-content: center;
  flex: 0.5;
  padding-left: 100px;
  padding-top: 20px;
}


@media screen and (max-width: 1500px) {
  .head1{
    font-size: 1.5em;
  }
  .head2{
    font-size: 1.5em;
  }

  .contentlist{
    font-size: 1.7em;
  }
  .contentlistitem{
    font-size: 1em;
  }
  
}

@media screen and (max-width: 700px) {
  .homepage1 {
      display: flex;
      padding: 15px;
      flex-direction: column;
  }
}

/* Media query for max-width: 1200px */
@media screen and (max-width: 1200px) {
  .homepage-title{
    display: inline;
    text-align: center;
    margin: 20px;
    padding: 20px; 
    color: white;
    cursor: pointer;
    border-radius: 20px;
    transition: all 0.3s;
    border: #A23A38 solid 2px;
    background: rgba(200, 82, 82, 0.702);
    backdrop-filter: saturate(180%) blur(10px);
    -webkit-box-shadow: 0px 20px 20px -17px rgba(219, 75, 75, 0.645);
    -moz-box-shadow: 0px 20px 20px -17px rgba(219, 75, 75,0.645);
    box-shadow: 0px 20px 20px -17px rgba(219, 75, 75, 0.645);
    &:hover{
      transform: scale(1.1);
    }
  }

  .homepage1 {
      flex-direction: column;
      align-items: center;
      margin-bottom: 10px;
  }

  .text-1 {
      margin: 30px 0;
  }

  .dosa {
      padding: 0;
  }

  .contentlistitem {
    padding: 0;
  }
}

/* Media query for max-width: 900px */
@media screen and (max-width: 900px) {

  .main1{
    background-image: url('../assests/image-3.png');
  }

  .homepage1{
    background: rgba(86, 86, 86, 0.671);
    backdrop-filter: saturate(180%) blur(5px);
  }
  
  .contentlistitem{
    color: #fff;
  }
  .contentlist{
    color: #fff;
  }
  #twh1{
    color: #fff;
  }

  #twsp{
    color: #c54745;
  }

  .typewriter {
      font-size: 1.5rem;
  }

  .head1 {
    font-size: 3rem;
    padding-top: 90px;
  }

  .head2 {
    font-size: 3rem;
  }

  .contentlist {
      font-size: 2.4rem;
  }

  .contentlistitem {
      font-size: 1.8rem;
      padding: 0;
  }
}

/* Media query for max-width: 650px */
@media screen and (max-width: 650px) {
  #twh1{
      font-size: 2rem;
  }

  .head1 {
    font-size: 2.4rem;
    padding-top: 20px;
  }

  .head2 {
    font-size: 2.4rem;
  }

  .contentlist {
      font-size: 1.8rem;
  }

  .contentlistitem {
      font-size: 1.2rem;
      padding: 0;
  }
}


@media screen and (max-width: 460px) {
  #twh1{
    font-size: 6.8vw;
  }
  .text-1{
    margin-top: 10px;
  }

  .contentlist {
    text-align: left;
  }
  .contentlistitem{
    font-size: .9rem;
  }
}
